<template>
  <vuestic-widget>
    <div class="row">
      <div class="col-md-12 d-flex justify-content-end align-items-center">
        <button class="btn btn-primary btn-sm mr-2" @click="onRefresh()" :disabled="broadcastFetching">
          <span>Refresh <i class="fa fa-refresh"></i></span>
        </button>
        <button class="btn btn-primary btn-sm" @click="onNewBroadcast()" :disabled="broadcastFetching">
          <span>New Broadcast <i class="fa fa-plus"></i></span>
        </button>
      </div>
      <div class="col-md-12 mt-3">
        <div class="pb-2 mb-3">
          <div class="d-flex flex-row justify-content-end align-items-center right-filter-wrapper">
            <div class="d-block">
              <multiselect
                class="filter-select d-inline-block"
                style="width: 150px;"
                v-model="selectedStatus"
                :options="statusList"
                :multiple="false"
                :close-on-select="true"
                :clear-on-select="false"
                :preserve-search="true"
                placeholder=""
                label="label"
                track-by="id"
                :preselect-first="true"
                :show-labels="false"
                :searchable="false"
                :allowEmpty="false"
                @input="onChangeFilter"
              ></multiselect>
            </div>
            <div class="d-flex align-items-center justify-content-end flex-row mr-2">
              <search-input name="searchInput" v-model="searchFilter" @input="onChangeFilter" />
            </div>
            <div class="">
              <button class="btn btn-sm btn-primary pull-right" @click="onResetFilter()">
                <span>Reset</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12">
        <div class="d-flex justify-content-center" v-if="broadcastFetching">
          <span class="atom-spinner-wrapper">
            <atom-spinner slot="loading" :animation-duration="1500" :size="80" color="rgb(53,120,198)" />
          </span>
        </div>
        <datatable v-else v-bind="this.tableConfig" class="le-datatable" />
      </div>
    </div>

    <vuestic-modal :isOpen="isOpenModalView" :hideDefaultActions="true" @cancel="isOpenModalView = false" okText="Ok"
      :cancelShown="false" @ok="isOpenModalView = false">
      <span slot="title">Broadcast Message</span>
      <div v-if="broadcastSelected" v-html="broadcastSelected.message"></div>
      <div v-if="broadcastSelected && broadcastSelected.images && broadcastSelected.images.length" class="row">
        <div v-for="(item, key) in broadcastSelected.images" :key="key" class="col-md-6">
          <img :src="item.path" />
        </div>
      </div>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalDelete" :hideDefaultActions="true" @cancel="closeModalDelete" okText="Delete"
      :cancelShown="false" @ok="onDeleteBroadcast" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Delete Broadcast</span>
      <p>
        Are you sure you want to delete this broadcast
        <strong>{{ broadcastSelected ? broadcastSelected.name : "" }}</strong>
      </p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalStop" :hideDefaultActions="true" @cancel="onCloseModalStop" okText="Stop"
      :cancelShown="false" @ok="onStopBroadcast" okClass="btn btn-danger btn-primary">
      <span slot="title" class="text-danger font-weight-bold">Stop Broadcast</span>
      <p>
        Are you sure you want to stop this broadcast
        <strong>{{ broadcastSelected ? broadcastSelected.name : "" }}</strong>
      </p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalReRun" :hideDefaultActions="true" @cancel="isOpenModalReRun = false" okText="Run"
      :cancelShown="false" @ok="onRunBroadcast" okClass="btn btn-primary">
      <span slot="title" class="text-primary font-weight-bold">Run Broadcast</span>
      <p>
        Are you sure you want to rerun this broadcast
        <strong>{{ broadcastSelected ? broadcastSelected.name : "" }}</strong>
      </p>
    </vuestic-modal>

    <vuestic-modal :isOpen="isOpenModalTags" @cancel="onCloseModalTag" :cancelShown="false" :okShown="false">
      <span slot="title">Tag Broadcast</span>

      <ValidationObserver v-slot="{ handleSubmit, invalid, dirty }">
        <form @submit.prevent="handleSubmit(onTagBroadcast)">
          <div class="row">
            <div class="col-md-12">
              <tag-selector v-if="broadcastSelected.status !== 'completed'" v-model="tagsSelected"></tag-selector>
              <div v-else class="tag-wrapper">
                <span class="tag noselect" v-for="(item, key) in broadcastSelected.tags" :key="key">
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
          <div class="row mt-5" v-if="broadcastSelected.status !== 'completed'">
            <div class="col-md-12 d-flex justify-content-center">
              <button class="btn btn-primary" :disabled="broadcastProcessing ||
                invalid ||
                broadcastSelected.status === 'completed'
                ">
                <atom-spinner v-if="broadcastProcessing" slot="loading" :animation-duration="1500" :size="14"
                  color="#FFF" />
                <span v-else>Update</span>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </vuestic-modal>
  </vuestic-widget>
</template>
  
<script>
import Vue from "vue";
import { mapState } from "vuex";
import components from "../../../common/tables/comps";
import TagSelector from "../../../common/TagSelector";
import SearchInput from '../../../common/SearchInput'

export default {
  components: {
    TagSelector,
    SearchInput,
  },
  
  props: {
    provider: {
      type: String,
      default: null,
    }
  },

  data() {
    return {
      searchFilter: '',
      isOpenModalTags: false,
      isOpenModalView: false,
      isOpenModalStop: false,
      broadcastSelected: {
        tags: [],
      },
      tagsSelected: [],
      loading: false,
      isOpenModalDelete: false,
      isOpenModalReRun: false,
      tableConfig: {
        supportBackup: false,
        supportNested: false,
        HeaderSettings: false,
        tblClass: "table-bordered",
        tblStyle: "color: #666",
        pageSizeOptions: [10, 25, 50, 100],
        columns: [
        ],
        data: [],
        total: 0,
        // selection: [],  //if this is present the row selector shows up..
        summary: {},
        query: {},
        // any other staff that you want to pass to dynamic components (thComp / tdComp / nested components)
        xprops: {
          eventbus: new Vue(),
        },
      },
      submitDataOrg: {
        id: null,
        name: "",
        tags: [],
        scheduled_at: "",
        message: "",
      },
      submitData: {
        id: null,
        name: "",
        tags: [],
        send_at: "now",
        scheduled_at: "",
        message: "",
      },
      statusList: [],
      selectedStatus: null,
    };
  },

  created() {
    if (this.provider === 'email') {
      this.tableConfig.columns = [
        { title: "Name", field: "name", label: "Name", thClass: "left", tdClass: "left", sortable: true, visible: true, },
        { title: "Tags", field: "tags", label: "Tags", tdClass: "center", tdComp: "TdTagsCount", sortable: false, visible: true, },
        { title: "Delivered", field: "total_delivered", label: "Delivered", tdClass: "center", sortable: false, visible: true, },
        { title: "Opened", field: "total_opened", label: "Opened", tdClass: "center", sortable: false, visible: true, },
        { title: "Clicked", field: "total_clicked", label: "Clicked", tdClass: "center", sortable: false, visible: true, },
        { title: "Bounces", field: "total_bounce", label: "Bounces", tdClass: "center", sortable: false, visible: true, },
        { title: "SpamReports", field: "total_spamreport", label: "SpamReports", tdClass: "center", sortable: false, visible: true, },
        { title: "Unsubscribes", field: "total_unsubscribed", label: "Unsubscribes", tdClass: "center", sortable: false, visible: true, },
        { title: "Status", field: "status", label: "Status", tdClass: "center", tdComp: "TdBroadcastStatus", sortable: false, visible: true, },
        { title: "ScheduledAt", field: "scheduled_at", label: "ScheduledAt", tdClass: "center", tdComp: "TdTimestamp", sortable: true, visible: true, },
        { title: "SentAt", field: "processed_at", label: "SentAt", tdClass: "center", tdComp: "TdTimestamp", sortable: true, visible: true, },
        { title: "", tdComp: "BroadcastMore", sortable: false, visible: true, },
      ]
    } else {
      this.tableConfig.columns = [
        { title: "Name", field: "name", label: "Name", thClass: "left", tdClass: "left", sortable: true, visible: true, },
        { title: "Tags", field: "tags", label: "Tags", tdClass: "center", tdComp: "TdTagsCount", sortable: false, visible: true, },
        { title: "Credit / SMS", field: "total_credit", label: "Credit / SMS", tdClass: "center", sortable: false, visible: true, },
        { title: "Total Sent", field: "total_sent", label: "Total Sent", tdClass: "center", sortable: false, visible: true, },
        { title: "Total Failed", field: "total_failed", label: "Total Failed", tdClass: "center", sortable: false, visible: true, },
        { title: "Opt Outs", field: "opt_outs", label: "Opt Outs", tdClass: "center", sortable: false, visible: true, },
        { title: "Link Opened", field: "link_opened", label: "Link Opened", tdClass: "center", sortable: false, visible: true, },
        { title: "Status", field: "status", label: "Status", tdClass: "center", tdComp: "TdBroadcastStatus", sortable: false, visible: true, },
        { title: "ScheduledAt", field: "scheduled_at", label: "ScheduledAt", tdClass: "center", tdComp: "TdTimestamp", sortable: true, visible: true, },
        { title: "SentAt", field: "processed_at", label: "SentAt", tdClass: "center", tdComp: "TdTimestamp", sortable: true, visible: true, },
        { title: "", tdComp: "BroadcastMore", sortable: false, visible: true, },
      ]
    }
    this.submitData = Vue.util.extend({}, this.submitDataOrg);

    this.tableConfig.xprops.eventbus.$on("onReRun", (row) => {
      this.broadcastSelected = row;
      this.isOpenModalReRun = true;
    });

    this.tableConfig.xprops.eventbus.$on("onView", (row) => {
      this.broadcastSelected = row;
      this.isOpenModalView = true;
    });

    this.tableConfig.xprops.eventbus.$on("onEdit", (row) => {
      this.$router.push({
        name: "business.broadcast.edit",
        params: { id: row.id },
      });
    });

    this.tableConfig.xprops.eventbus.$on("onDelete", (row) => {
      this.broadcastSelected = row;
      this.isOpenModalDelete = true;
    });

    this.tableConfig.xprops.eventbus.$on("onDuplicate", (row) => {
      this.$router.push({
        name: "business.broadcast.duplicate",
        params: { id: row.id },
      });
    });

    this.tableConfig.xprops.eventbus.$on("onReport", (row) => {
      this.$router.push({
        name: "business.broadcast.report",
        params: { id: row.id },
      });
    });

    this.tableConfig.xprops.eventbus.$on("onTagLinkClick", (row) => {
      this.onModalTag(row);
    });

    this.tableConfig.xprops.eventbus.$on("onStop", (row) => {
      this.onModalStop(row);
    });

    this.tableConfig.xprops.eventbus.$on("onRefresh", (row) => {
      this.refresh(row)
    });

    const offset =
      (this.broadcastPage - 1 >= 0 ? this.broadcastPage - 1 : 0) *
      this.broadcastPerPage;

    this.tableConfig.query = {
      offset,
      limit: this.broadcastPerPage,
    };

    this.initFilter()
  },

  watch: {
    broadcasts: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.data = newValue;
        }
      },
      deep: true,
    },
    broadcastsTotal: {
      handler: function (newValue, oldValue) {
        if (newValue != oldValue) {
          this.tableConfig.total = newValue;
        }
      },
      deep: true,
    },
    "tableConfig.query": {
      handler() {
        this.loadBroadcasts();
      },
      deep: true,
    },
  },

  computed: {
    user() {
      return this.$store.state.auth.user;
    },

    smsLimit() {
      if (
        this.user &&
        this.user.business &&
        this.user.business.messages >= this.user.business.number_of_messages
      )
        return true;
      return false;
    },

    agencyEmail() {
      return (this.user && this.user.agency && this.user.agency.email) || "";
    },

    ...mapState("broadcast", {
      broadcastFetching: "fetching",
      broadcastDidFetch: "didFetch",
      broadcastProcessing: "processing",
      broadcastPage: "page",
      broadcastPerPage: "perPage",
      broadcastFilter: "filter",
      broadcasts: "data",
      broadcastsTotal: "total",
    }),
  },
  mounted() { 
  },
  methods: {
    initFilter() {
      this.statusList = [
        { id: 0, label: 'All' },
        { id: 1, label: 'Completed' },
        { id: 2, label: 'In Progress' },
        { id: 3, label: 'Scheduled' },
        { id: 4, label: 'Draft' },
      ]
      this.selectedStatus = this.statusList[0]
    },

    onChangeFilter() {
      this.tableConfig.query.offset = 0
      this.tableConfig.query.status = this.selectedStatus ? this.selectedStatus.id: 0,
      this.loadBroadcasts()
    },

    onResetFilter() {
      this.searchFilter = ''
      this.onRefresh()
    },

    onNewBroadcast() {
      if (this.smsLimit) {
        Vue.$toast.open({
          message: "You have reached messages limit. Please contact agency to get more credits.",
          type: "error",
        });
      return;
      }
      this.$router.push({ name: 'business.broadcast.create', query: { type: this.provider } })
    },

    onRefresh() {
      this.loadBroadcasts();
    },

    onModalStop(row) {
      this.broadcastSelected = row;
      this.isOpenModalStop = true;
    },

    onCloseModalStop() {
      this.isOpenModalStop = false
    },

    onModalTag(row) {
      this.broadcastSelected = row;
      this.tagsSelected = this.broadcastSelected.tags;
      this.isOpenModalTags = true;
    },

    onCloseModalTag() {
      this.isOpenModalTags = false;
    },

    onTagBroadcast() {
      const requestParam = {
        id: this.broadcastSelected.id,
        data: {
          tags: this.tagsSelected,
        },
      };
      this.$store
        .dispatch("broadcast/tags", requestParam)
        .then(() => {
          this.isOpenModalTags = false;
        })
        .catch((err) => {
          this.isOpenModalTags = false;
        });
    },

    loadBroadcasts() {
      const { query } = this.tableConfig;
      const page = query.offset
        ? Math.floor(query.offset / query.limit) + 1
        : 1;
      const param = {
        page,
        per_page: query.limit,
        order: query.order,
        sort: query.sort,
        status: query.status,
        search: this.searchFilter,
        provider: this.provider,
      };

      this.$store.dispatch("broadcast/fetch", param);
    },

    closeModalDelete() {
      this.isOpenModalDelete = false;
    },
    
    onStopBroadcast() {
      this.$store
        .dispatch("broadcast/stop", this.broadcastSelected.id)
        .then(() => {
          this.isOpenModalStop = false;
          this.loadBroadcasts();
        })
        .catch((err) => {
          this.isOpenModalStop = false;
        });
    },

    onDeleteBroadcast() {
      this.$store
        .dispatch("broadcast/delete", this.broadcastSelected.id)
        .then(() => {
          this.isOpenModalDelete = false;
          this.loadBroadcasts();
        })
        .catch((err) => {
          this.isOpenModalDelete = false;
        });
    },

    onRunBroadcast() {
      this.$store
        .dispatch("broadcast/rerun", this.broadcastSelected.id)
        .then(() => {
          this.isOpenModalReRun = false;
          this.loadBroadcasts();
        })
        .catch((err) => {
          this.isOpenModalReRun = false;
        });
    },

    refresh(broadcast) {
      this.$store
        .dispatch('broadcast/refresh', broadcast.id)
    },
  },
};
</script>
  
<style lang="scss" scoped>
.message-textarea {
  width: 100%;
  border-bottom: 1px solid #e7e7e7;
  outline: none;
  box-shadow: none;
}

.mergefield-tags {
  cursor: pointer;
  font-size: 12px;
}

.tag-wrapper {
  span.tag {
    cursor: pointer;
    background-color: #3578c6;
    color: #fff;
    border-radius: 5px;
    padding: 3px 5px;
    font-size: 0.85em;
    display: block;
    margin-right: 5px;
    display: inline-block;
  }
}
</style>
  